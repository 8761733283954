import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import * as t from '@rushplay/theme'
import * as common from '@rushplay/common'
import styled from '@emotion/styled'

import { baseStyles } from '../menu-item/menu-item-paradiseplay'

const DisabledWalletLink = styled.span`
  ${baseStyles};
  color: ${t.color('g-text', 0.3)};
  opacity: 0.35;
  cursor: not-allowed;

  &:hover,
  &:active {
    background-color: initial;
    box-shadow: initial;
  }
`

const WalletLink = styled(ReactRouter.Link)`
  ${baseStyles};
  color: ${props => t.color(props.color)(props)};

  &:active {
    box-shadow: inset 4px 0 0 0 ${props => t.color(props.color)(props)};
  }
`

const variants = {
  active: {
    rotate: [0, 20, -20, 20, -20, 0],
    transition: {
      duration: 1,
      loop: Infinity,
      repeatDelay: 6,
    },
  },
  inactive: {
    rotate: 0,
  },
}

export function WalletMenuItem(props) {
  const Icon = props.icon

  if (props.disabled) {
    return (
      <DisabledWalletLink data-testid={props.testId}>
        <Icon />
        <common.Box
          fontSize={3}
          paddingLeft={1}
          display="inline-flex"
          color="g-text"
        >
          {props.children}
        </common.Box>
      </DisabledWalletLink>
    )
  }

  return (
    <WalletLink
      data-testid={props.testId}
      color={props.highlightColor}
      to={props.to}
      onClick={props.onClick}
    >
      <motion.div
        variants={variants}
        animate={props.animate ? 'active' : 'inactive'}
      >
        <Icon />
      </motion.div>
      <common.Box
        fontSize={3}
        paddingLeft={1}
        display="inline-flex"
        color="g-text"
      >
        {props.children}
      </common.Box>
    </WalletLink>
  )
}

WalletMenuItem.propTypes = {
  animate: PropTypes.bool,
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  highlightColor: PropTypes.string,
  icon: PropTypes.func.isRequired,
  testId: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default WalletMenuItem
